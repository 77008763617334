<template>
  <v-dialog
    v-model="visible"
    max-width="800"
    scrim="transparent"
  >
    <v-card
      border
      flat
      tile
    >
      <v-card-text class="mnh-250 mt-4">
        <template v-if="loaded">
          <QuestionSet
            v-model="claim"
            @back="backFromQuestion($event)"
            @change:attachments="loadAttachments()"
            @next="forwardFromQuestion($event)"
            :attachment-group-id="familySubsidy?.group_id"
            :attachment-owner-id="claim.id"
            :attachment-owner-type="'Claim'"
            :attachments="claimAttachments"
            :processing="processing"
            :questions="validQuestions"
            :schema="schema.definition"
            :section="section"
            :transition-name="transitionName"
            key-name="question"
          />
        </template>

        <template v-else>
          <v-progress-linear
            class="my-8"
            indeterminate
          />
        </template>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import API from '@/shared/mixins/api';
import Questionable from '@/shared/mixins/questionable';
import QuestionSet from '@/shared/components/form/QuestionSet.vue';
import Stepper from '@/shared/mixins/stepper';

export default {
  compatConfig: { MODE: 2 },

  components: {
    QuestionSet,
  },

  mixins: [API, Questionable, Stepper],

  props: {
    familySubsidy: {
      type: Object,
      default: null,
    },
    grantId: {
      type: String,
      default: null,
    },
    schemaId: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
  },

  emits: ['save'],

  data() {
    return {
      claim: null,
      claimAttachments: [],
      index: 0,
      ownerId: null,
      ownerType: 'Schema',
      processing: false,
      schema: null,
      visible: false,
    };
  },

  computed: {
    loaded() {
      return this.claim && this.schema && this.questions && this.questions.length > 0;
    },

    claimRepository() {
      if (this.$role === 'parent') return this.api.parent.claim;
      if (this.$role === 'manager') return this.api.manager.claim;
      return null;
    },

    attachmentRepository() {
      if (this.$role === 'parent') return this.api.member.attachment;
      if (this.$role === 'manager') return this.api.manager.attachment;
      return null;
    },
  },

  methods: {
    async backFromQuestion(index) {
      this.processing = true;
      this.save();

      if (index - 1 < 0) {
        this.processing = false;
        this.close();
      } else {
        this.goToQuestion(index - 1);
      }
    },

    async forwardFromQuestion(index) {
      this.processing = true;
      this.save();

      await this.validate();
      if (index + 1 >= this.validQuestions.length) {
        this.submitClaim();
      } else {
        this.goToQuestion(index + 1);
      }
    },

    goToQuestion(newIndex) {
      this.processing = false;
      this.section = null;
      this.index = newIndex;
      setTimeout(() => {
        this.section = `question-${newIndex}`;
      }, 600);
    },

    close() {
      this.visible = false;
      this.claim = null;
      this.schema = null;

      const queryParams = { ...this.$route.query };
      delete queryParams.section;
      delete queryParams.step;
      this.$router.push({ query: queryParams });
    },

    async loadAttachments() {
      this.processing = true;
      const resp = await this.attachmentRepository.index({
        owner_id: this.claim.id,
        owner_type: 'Claim',
      });
      if (resp?.status === 200) {
        this.claimAttachments = resp.data;
      }
      this.processing = false;
      return true;
    },

    async open(claimId) {
      this.visible = true;
      this.processing = true;
      const queryParams = { ...this.$route.query };
      queryParams.section = 'question-0';
      queryParams.step = 1;
      this.$router.push({ query: queryParams });

      this.questions = [];

      await this.setClaim(claimId);
      await this.loadQuestions();
      await this.loadAttachments();

      this.section = 'question-0';
      this.processing = false;
    },

    async save() {
      await this.claimRepository.update(this.claim.id, this.claim);
      this.$emit('save');
    },

    async setClaim(claimId) {
      let resp;
      if (claimId) {
        resp = await this.claimRepository.get(claimId);
        if (resp?.status !== 200) return;
      } else {
        const params = {
          custom: {},
          family_subsidy_id: this.familySubsidy?.id,
          grant_id: this.grantId,
          schema_id: this.schemaId,
        };
        resp = await this.claimRepository.create(params);
        if (resp?.status !== 201) return;
      }
      this.claim = resp.data;

      this.schema = this.$store.state.schemas[this.claim.schema_id || this.schemaId];
      this.ownerId = this.claim.schema_id || this.schemaId;
    },

    async submitClaim() {
      const resp = await this.claimRepository.submit(this.claim.id);
      if (resp?.status !== 200) {
        this.processing = false;
      } else {
        this.$emit('save');
        this.processing = false;
        this.$eventBus.$emit('chime', 'Claim submitted.');
        this.close();
      }
    },
  },
};
</script>
